<template>
  <div class="page-wrap">
    <div class="section-wrap">
      <div class="wrapper-left">
        <breadcrumbs :crumbs="pageData.crumbs"></breadcrumbs>
        <div class="wrapper-content">
          <div class="wrapper-list">
            <div class="project-info">
              <div class="poster" style="margin-top: 30px">
                <el-carousel height="240px" v-if="state.image">
                  <el-carousel-item
                    v-for="item in state.image.split(',')"
                    :key="item"
                  >
                    <div class="wrap">
                      <img :src="item" />
                    </div>
                  </el-carousel-item>
                </el-carousel>
                <video :src="state.video" v-else-if="state.video"></video>
                <img :src="state.partnership || state.coverImage" v-else />
              </div>
              <div class="info">
                <div class="title">
                  <h3>
                    <span
                      class="title-line"
                      :title="state.title || state.investIntent"
                      >{{ state.title || state.investIntent }}</span
                    >
                  </h3>
                </div>
              </div>
              <div class="project-detail">
                <YJSL class="jysl"></YJSL>
                <div>
                  <el-tag>{{ state.tips }}</el-tag>
                </div>
              </div>

              <div class="desc">
                {{ state.article }}
              </div>
            </div>
            <div class="project-detail">
              <div class="comment" style="margin-top: 40px">
                <div class="block">
                  <div class="tag">大赛进程</div>
                  <div>
                    <el-timeline class="timeline">
                      <el-timeline-item
                        class="lineitem"
                        :color="`${
                          index + 1 <= Number(state.matchStatus) ? '#00b68e' : '#cccccc'
                        }`"
                        v-for="(activity, index) in definedMatchStatus"
                        :key="index"
                        :timestamp="activity.time"
                      >
                        <span style="display: flex; flex-direction: column">
                          <span style="margin: 10px 0; font-size: 16px">
                            {{ activity.label }}
                          </span>
                          <span style="color: #8c8c8c; font-size: 14px">
                            {{ activity.upTime }}
                          </span>
                        </span>
                      </el-timeline-item>
                    </el-timeline>
                    <div>
                      大赛起止：<span
                        >{{ state.applicationTimeStart }} 至
                        {{ state.applicationTimeEnd }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div style="margin-top: 40px">
                <div class="block">
                  <div class="tag">大赛详情</div>
                  <div class="desc" style="padding-top: 15px">
                    <ul>
                      <li>
                        <span class="tit">主办单位：</span
                        ><span>{{ state.hostUnit }}</span>
                      </li>
                      <li>
                        <span class="tit">协办单位：</span
                        ><span>{{ state.assistUnit }}</span>
                      </li>
                      <li>
                        <span class="tit">报名时间：</span
                        ><span
                          >{{ state.holdingTimeStart }} 至
                          {{ state.holdingTimeEnd }}</span
                        >
                      </li>
                      <li>
                        <span class="tit">联 系 人：</span
                        ><span>{{ state.contactName }}</span>
                      </li>
                      <li>
                        <span class="tit">联系电话：</span
                        ><span>{{ state.phoneNumber }}</span>
                      </li>
                      <li>
                        <span class="tit">联系邮箱：</span
                        ><span>{{ state.mobileNumber }}</span>
                      </li>
                      <li>
                        <span class="tit">大赛地址：</span
                        ><span>{{ state.address }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div style="margin-top: 40px">
                <div class="block">
                  <div class="tag">大赛描述</div>
                  <div class="desc" style="padding-top: 15px">
                    {{ state.content }}
                  </div>
                </div>
              </div>

              <div class="comment" style="margin-top: 40px">
                <div class="block">
                  <div class="tag">评论</div>
                  <div class="desc" style="padding-top: 15px">
                    <Comment
                      :id="pageData.id"
                      :type="pageData.commentType"
                    ></Comment>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="wrapper-right">
            <QuickPublishProject></QuickPublishProject>
            <BlockSuggestProject class="box-suggest"></BlockSuggestProject>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, defineAsyncComponent, computed, defineProps } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Options, Vue } from 'vue-class-component'
import ItemProject from '@/components/ItemProject.vue'
import QuickPublishProject from '@/components/QuickPublishProject.vue'
import BlockSuggestProject from '@/components/BlockSuggestProject.vue'
import YJSL from '@/components/YJSL.vue'
import Comment from '@/components/Comment.vue'
import PayItem from '@/components/PayItem.vue'
import { Api } from '@/services/http'
import { useStoreData } from '@/hooks/index'

const store = useStore()
const props = defineProps({
  type: {
    type: String,
    required: false,
  },
})
const rate = ref(0)
const match_status = useStoreData([], () => {
  return store.state.base.businessItems
})
const definedMatchStatus = computed(() => {
  const val = match_status.value
  return val.find(item => item.type == 'match_status')?.items
})
let pageData = reactive({
  id: '',
  commentType: 1,
  info: {},
  matchSteps: [
    {
      label: '111',
    },
    {
      label: '222',
    },
    {
      label: '333',
    },
  ],
  crumbs: [],
})
let state = computed(() => pageData.info)
const mounted = async () => {
  const route = useRoute()
  const id = route.params.id
  const path = route.path
  const typeMapper = [
    {
      regex: /coop/gi,
      value: 'partnership',
      commentType: 1,
    },
    {
      regex: /fund/gi,
      value: 'funder',
      commentType: 4,
    },
  ]
  const find = typeMapper.find(item => item.regex.test(path))

  let commentType = 3

  pageData.id = id
  const info = await Api.get(`/client-api/hslj/detail?id=` + id)

  pageData.commentType = commentType
  pageData.info = { ...info }
  console.log('页面数据', pageData)
  rate.value = info.userStarLevel
  pageData.crumbs = [
    {
      name: info.title || info.investIntent,
    },
  ]
}
mounted()
</script>
<style lang="scss" scoped>
.section-wrap {
  padding-bottom: 30px;
  .wrapper-left {
    padding-top: 30px;
  }
  .wrapper-content {
    display: flex;
    .wrapper-right {
      flex-basis: 260px;
      margin-left: 30px;
    }
    .box-suggest {
      margin-top: 20px;
    }
  }
  .wrapper-list {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
  }
  .block {
    text-align: left;
    .tag {
      height: 16px;
      border-left: 5px solid #fc9507;
      line-height: 16px;
      padding-left: 10px;
      font-weight: bold;
    }
    .cont {
      padding: 10px;
      font-size: 14px;
    }
  }
  .jysl {
    position: absolute;
    right: 0;
    width: 210px;
    top: 5px;
  }
  // 项目摘要
  .project-info {
    position: relative;
    h3 {
      font-size: 22px;
      padding-bottom: 10px;
    }

    .poster {
      height: 300px;
      img,
      video {
        height: 100%;
      }
      .wrap {
        height: 100%;
      }
    }
    .info {
      flex: 1;
      text-align: left;
      .title {
        position: relative;
      }

      .title-line {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 显示的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5em; /* 每行的高度 */
        max-height: 3em; /* line-height * 行数 */
      }
    }

    .block {
      margin-bottom: 10px;
      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
      }
    }

    .block-info {
      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      li {
        vertical-align: top;

        display: inline-block;
        margin-bottom: 3px;
        margin-right: 10px;
      }
      em {
        font-style: normal;
      }
      span {
        display: inline-block;
        border: 1px solid #b6daf1;
        padding: 0 4px;
        border-radius: 4px;
        background: #dbf1ff;
        color: #666;
      }
    }
    .block-info-inline {
      span {
        display: inline-block;
        border: 1px solid #b6daf1;
        padding: 0 4px;
        border-radius: 4px;
        background: #dbf1ff;
        color: #666;
      }
      li {
        vertical-align: top;
        width: auto;
        display: inline-block;
        margin-right: 10px;
      }
    }
    .block-author {
      .cont {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      .avator {
        display: flex;
        .svg-icon {
          width: 40px;
          height: 40px;
          margin-right: 15px;
        }
        .em {
          font-size: 12px;
          color: #666;
        }
      }
      .cont-ext {
        font-size: 14px;
        .svg-icon {
          margin-left: 10px;
        }
      }
    }
  }
  .desc {
    line-height: 32px;
    font-size: 16px;
    white-space: pre-wrap;
    padding-top: 20px;
    text-align: left;
  }
  // 项目需求细节
  .project-detail {
    text-align: left;
    position: relative;
    a {
      color: #237ece;
    }

    .comment,
    .contact {
    }
    .tag-item {
      display: inline-block;
      border: 1px solid #d7d7d7;
      padding: 0 4px;
      border-radius: 4px;
      background: #f1f1f1;
      color: #666;
      margin-right: 10px;
      margin-bottom: 20px;
    }
  }
  .contact {
    .desc {
      display: flex;
      justify-content: space-between;
    }
    .svg-icon {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    .avator {
      flex: 1;
      display: flex;
      align-items: center;
    }
    .btn {
      display: inline-block;
      padding: 2px 8px;
      background-color: #ff983a;
      border-radius: 4px;
      color: white;
      margin-left: 5px;
      width: 100px;
      cursor: pointer;
      flex-basis: 66px;
      text-align: center;
      height: 25px;
      &:hover {
        background-color: #ba6210;
      }
    }
  }
}

.timeline {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  background: #f3f3f3;
  /* justify-content: space-between; */
  padding: 30px;
  box-sizing: border-box;
  padding-left: 208px;
  margin-top: 20px;
  border-radius: 20px;
  padding-bottom: 48px;

  .lineitem {
    transform: translateX(50%);
    width: 25%;
  }
}

::v-deep .el-timeline-item__tail {
  border-left: none;
  border-top: 2px solid #e4e7ed;
  width: 100%;
  position: absolute;
  top: 6px;
}

::v-deep .el-timeline-item__wrapper {
  padding-left: 0;
  position: absolute;
  top: 20px;
  transform: translateX(-50%);
  text-align: center;
}

::v-deep .el-timeline-item__timestamp {
  font-size: 14px;
}
</style>
